import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { MapContainer, TileLayer, Circle, Tooltip, Rectangle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const MashhadDistrict2Map = () => {
  // Center coordinates for Mashhad District 2
  const DISTRICT_2_CENTER = [36.3200, 59.5300];

  // Approximate bounds for District 2
  const DISTRICT_2_BOUNDS = [
    [36.3000, 59.5100], // Southwest corner
    [36.3400, 59.5500]  // Northeast corner
  ];

  // Simulated hot zones data for District 2
  const hotZones = [
    { name: "Mellat Park", coordinates: [36.3227, 59.5650], intensity: 80 },
    { name: "Ferdowsi University", coordinates: [36.3102, 59.5359], intensity: 70 },
    { name: "Sajjad Boulevard", coordinates: [36.3250, 59.5200], intensity: 60 },
    { name: "Yas Commercial Complex", coordinates: [36.3180, 59.5320], intensity: 50 },
    { name: "Sadaf Cultural Complex", coordinates: [36.3130, 59.5400], intensity: 40 },
  ];

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Hot Zone Map of Mashhad District 2
        </Typography>
        <div style={{ height: '500px', width: '100%' }}>
          <MapContainer center={DISTRICT_2_CENTER} zoom={14} style={{ height: '100%', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Rectangle bounds={DISTRICT_2_BOUNDS} pathOptions={{ color: 'blue', weight: 2, fillOpacity: 0.1 }} />
            {hotZones.map((zone) => (
              <Circle
                key={zone.name}
                center={zone.coordinates}
                pathOptions={{ fillColor: `rgba(255, 0, 0, ${zone.intensity / 100})`, color: 'red' }}
                radius={300}
              >
                <Tooltip direction="top" offset={[0, -20]} opacity={1} permanent>
                  {zone.name}
                </Tooltip>
              </Circle>
            ))}
          </MapContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default MashhadDistrict2Map;