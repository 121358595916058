

export const performanceTrendData = {
    medical: {
      'Diagnosis Accuracy': [
        { month: 'Jan', value: 75 },
        { month: 'Feb', value: 78 },
        { month: 'Mar', value: 82 },
        { month: 'Apr', value: 85 },
        { month: 'May', value: 88 },
        { month: 'Jun', value: 92 },
      ],
      'Patient Readmission Rate': [
        { month: 'Jan', value: 15 },
        { month: 'Feb', value: 14 },
        { month: 'Mar', value: 13 },
        { month: 'Apr', value: 12 },
        { month: 'May', value: 11 },
        { month: 'Jun', value: 10 },
      ],
    },
    operational: {
      'Resource Utilization': [
        { month: 'Jan', value: 70 },
        { month: 'Feb', value: 72 },
        { month: 'Mar', value: 75 },
        { month: 'Apr', value: 78 },
        { month: 'May', value: 80 },
        { month: 'Jun', value: 82 },
      ],
    },
    administrative: {
      'Process Automation': [
        { month: 'Jan', value: 60 },
        { month: 'Feb', value: 63 },
        { month: 'Mar', value: 65 },
        { month: 'Apr', value: 68 },
        { month: 'May', value: 70 },
        { month: 'Jun', value: 72 },
      ],
    },
    accounting: {
      'Billing Accuracy': [
        { month: 'Jan', value: 92 },
        { month: 'Feb', value: 93 },
        { month: 'Mar', value: 94 },
        { month: 'Apr', value: 95 },
        { month: 'May', value: 96 },
        { month: 'Jun', value: 97 },
      ],
    },
  };
  export  const multiMetricComparisonData = [
    { metric: 'Efficiency', medical: 15, administrative: 18, operational: 55, accounting: 77 },
    { metric: 'Cost Reduction', medical: 65, administrative: 64, operational: 33, accounting: 73 },
    { metric: 'Customer Satisfaction', medical: 61, administrative:28, operational: 77, accounting: 90 },
    { metric: 'Innovation', medical: 100, administrative: 78, operational: 46, accounting: 20 },
    { metric: 'Data Quality', medical: 88, administrative: 70, operational: 45, accounting: 30 },
  ];




 export const overallKpis = [
    { name: 'Patient Satisfaction', value: 85, target: 95 },
    { name: 'Diagnostic Accuracy', value: 92, target: 98 },
    { name: 'Cost Reduction', value: 15, target: 25 },
    { name: 'Data Analysis Projects', value: 12, target: 20 },
  ];

 export const divisionKpis = {
    medical: [
      { name: 'Diagnosis Accuracy', value: 92, target: 98 },
      { name: 'Treatment Efficacy', value: 10, target: 95 },
      { name: 'Patient Readmission Rate', value: 12, target: 20 },
      { name: 'AI-Assisted Diagnoses', value: 60, target: 80 },
      { name: 'Clinical Trial Matching', value: 70, target: 90 },
    ],
    operational: [
      { name: 'Resource Utilization', value: 78, target: 90 },
      { name: 'Patient Wait Time (mins)', value: 25, target: 95 },
      { name: 'Staff Productivity', value: 82, target: 95 },
      { name: 'Equipment Downtime', value: 8, target: 13 },
      { name: 'Inventory Turnover Rate', value: 12, target: 15 },
    ],
    administrative: [
      { name: 'Process Automation', value: 65, target: 85 },
      { name: 'Compliance Rate', value: 95, target: 100 },
      { name: 'Document Processing Time (hrs)', value: 24, target: 100 },
      { name: 'Staff Training Completion', value: 80, target: 100 },
      { name: 'Patient Data Accuracy', value: 96, target: 99 },
    ],
    accounting: [
      { name: 'Billing Accuracy', value: 98, target: 99 },
      { name: 'Revenue Cycle Days', value: 20, target: 30 },
      { name: 'Claim Denial Rate', value: 8, target: 65 },
      { name: 'Cost per Patient', value: 200, target: 1000 },
      { name: 'Profit Margin', value: 15, target: 20 },
    ],
  };

 export const revenueData = [
    { name: 'AI Services', value: 40, growth: 15 },
    { name: 'Software Sales', value: 30, growth: 10 },
    { name: 'Consulting', value: 20, growth: 5 },
    { name: 'Data Analysis', value: 10, growth: 20 },
  ];

 export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

 export const strategicGoals = [
    { name: 'Implement AI-driven diagnosis support', progress: 60, division: 'Medical' },
    { name: 'Optimize resource allocation', progress: 45, division: 'Operational' },
    { name: 'Automate administrative workflows', progress: 75, division: 'Administrative' },
    { name: 'Implement predictive financial modeling', progress: 30, division: 'Accounting' },
    { name: 'Develop integrated data platform', progress: 50, division: 'All' },
    { name: 'Launch patient mobile app', progress: 80, division: 'Medical' },
  ];

 export const dataIntegrationProgress = [
    { subject: 'Medical', A: 90, fullMark: 100 },
    { subject: 'Operational', A: 75, fullMark: 100 },
    { subject: 'Administrative', A: 80, fullMark: 100 },
    { subject: 'Accounting', A: 85, fullMark: 100 },
  ];

export const divisionDescriptions = {
    medical: {
      goals: "Enhance patient care through AI-driven diagnostics and personalized treatment plans.",
      scope: "Implement machine learning for disease prediction, optimize treatment protocols, and facilitate clinical trial matching."
    },
    operational: {
      goals: "Streamline clinic operations and improve resource utilization through data-driven decision making.",
      scope: "Optimize scheduling, predict resource needs, automate inventory management, and enhance overall operational efficiency."
    },
    administrative: {
      goals: "Automate administrative processes and improve compliance through intelligent systems.",
      scope: "Implement AI for document processing, automate reporting, enhance data accuracy, and ensure regulatory compliance."
    },
    accounting: {
      goals: "Optimize financial performance and provide predictive financial insights.",
      scope: "Implement AI for revenue cycle management, automate billing processes, provide predictive financial modeling, and optimize cost structures."
    }
  };