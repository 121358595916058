import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const PerformanceTrendCard = ({ division, translateWithLang  }) => {
  const performanceTrendData = {
    medical: {
      'Diagnosis Accuracy': [
        { month: 'Jan', value: 35 },
        { month: 'Feb', value: 58 },
        { month: 'Mar', value: 82 },
        { month: 'Apr', value: 55 },
        { month: 'May', value: 68 },
        { month: 'Jun', value: 72 },
      ],
      'Patient Readmission Rate': [
        { month: 'Jan', value: 0 },
        { month: 'Feb', value: 1 },
        { month: 'Mar', value: 1 },
        { month: 'Apr', value: 3 },
        { month: 'May', value: 5 },
        { month: 'Jun', value: 3 },
      ],
    },
    operational: {
      'Resource Utilization': [
        { month: 'Jan', value: 70 },
        { month: 'Feb', value: 72 },
        { month: 'Mar', value: 75 },
        { month: 'Apr', value: 78 },
        { month: 'May', value: 80 },
        { month: 'Jun', value: 82 },
      ],
      'Patient Wait Time (mins)': [
        { month: 'Jan', value: 33 },
        { month: 'Feb', value: 30 },
        { month: 'Mar', value: 20 },
        { month: 'Apr', value: 20 },
        { month: 'May', value: 10 },
        { month: 'Jun', value: 5 },
      ],
    },
    administrative: {
      'Compliance Rate': [
        { month: 'Jan', value: 60 },
        { month: 'Feb', value: 63 },
        { month: 'Mar', value: 65 },
        { month: 'Apr', value: 68 },
        { month: 'May', value: 70 },
        { month: 'Jun', value: 72 },
      ],
    },
    accounting: {
      'Billing Accuracy': [
        { month: 'Jan', value: 50 },
        { month: 'Feb', value: 63 },
        { month: 'Mar', value: 74 },
        { month: 'Apr', value: 45 },
        { month: 'May', value: 90 },
        { month: 'Jun', value: 87 },
      ],
    },
  };

  const kpiOptions = Object.keys(performanceTrendData[division]);
  const [selectedKPI, setSelectedKPI] = useState('');

  useEffect(() => {
    // Set the default KPI to the first option when the component mounts or division changes
    if (kpiOptions.length > 0) {
      setSelectedKPI(kpiOptions[0]);
    }
  }, [division]);

  const handleKPIChange = (event) => {
    setSelectedKPI(event.target.value);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>{translateWithLang("Performance Trend")}</Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="kpi-select-label">{translateWithLang("Select KPI")}</InputLabel>
          <Select
            labelId="kpi-select-label"
            value={selectedKPI}
            label={translateWithLang("Select KPI")}
            onChange={handleKPIChange}
          >
            {kpiOptions.map((kpi) => (
              <MenuItem key={kpi} value={kpi}>{translateWithLang(kpi)}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedKPI && (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={performanceTrendData[division][selectedKPI]}>
              <XAxis dataKey="month" tickFormatter={(value) => translateWithLang(value)} />
              <YAxis />
              <Tooltip
                formatter={(value) => [value, translateWithLang(selectedKPI)]}
                labelFormatter={(label) => translateWithLang(label)}
              />
              <Legend formatter={(value) => translateWithLang(value)} />
              <Line type="monotone" dataKey="value" stroke="#8884d8" name={translateWithLang(selectedKPI)} />
            </LineChart>
          </ResponsiveContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default PerformanceTrendCard;