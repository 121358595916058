import React, { useState, useCallback, useRef } from 'react';
import { Card, CardContent, Typography, Tabs, Tab, Box, LinearProgress, Grid, Button, Menu, MenuItem, Popper, Paper, ClickAwayListener   } from '@mui/material';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, ResponsiveContainer } from 'recharts';
import GeographicHotZoneMap from './GeographicHotZoneMap';
import { ReactComponent as Workflow } from './ProjectPlan.svg';
import salamatLogo from './salamat_logo.png';
import PerformanceTrendCard from './PerformanceTrendCard';
import { translate } from './translations';
import {
  overallKpis,
  divisionKpis,
  revenueData,
  strategicGoals,
  dataIntegrationProgress,
  performanceTrendData,
  multiMetricComparisonData,
  COLORS,
  divisionDescriptions
} from './dashboardData';



const StrategicPlanDashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
    const [language, setLanguage] = useState('en');
  const [openDivisions, setOpenDivisions] = useState(false);
  const divisionsRef = useRef(null);

     const handleDivisionsHover = () => {
    setOpenDivisions(true);
  };

  const handleDivisionsClose = () => {
    setOpenDivisions(false);
  };

  const handleDivisionClick = (division) => {
    // Handle division selection here
    console.log(`Selected division: ${division}`);
    handleDivisionsClose();
  };

  const divisions = [
    "Dentistry",
    "MMT",
    "Diabetes",
    "Laboratory",
    "Specialists",
    "Emergency",
    "...",
  ];
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleLanguage = () => {
    setLanguage(prevLang => prevLang === 'en' ? 'fa' : 'en');
  };

  const translateWithLang = useCallback((key) => translate(key, language), [language]);

  return (
    <Box sx={{ p: 5, bgcolor: 'grey.100', minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src= {salamatLogo}
            alt="Salamat Logo"
            style={{
              height: '50px',
              marginRight: '20px'
            }}
          />
          <Typography variant="h5">
            {translateWithLang("Integrated Strategic Plan Dashboard - AI/IT Division")}
          </Typography>
        </Box>
        <Button variant="contained" onClick={toggleLanguage}>
          {language === 'en' ? translateWithLang("فارسی") : translateWithLang("English")}
        </Button>
      </Box>

      <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label={translateWithLang("Overview")} />
        <Tab label={translateWithLang("Medical")} />
        <Tab label={translateWithLang("Operational")} />
        <Tab label={translateWithLang("Administrative")} />
        <Tab label={translateWithLang("Accounting" )}/>
          <Tab
          label={translateWithLang("Divisions")}
          ref={divisionsRef}
          onMouseEnter={handleDivisionsHover}
          onMouseLeave={handleDivisionsClose}
        />
      </Tabs>
               <Popper
        open={openDivisions}
        anchorEl={divisionsRef.current}
        placement="bottom-start"
        disablePortal={false}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              boundariesElement: 'viewport',
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleDivisionsClose}>
          <Paper
            onMouseEnter={handleDivisionsHover}
            onMouseLeave={handleDivisionsClose}
            sx={{
              p: 1,
              bgcolor: 'background.paper',
              boxShadow: 3,
              maxHeight: '300px',
              overflowY: 'auto'
            }}
          >
            {divisions.map((division) => (
              <Box
                key={division}
                onClick={() => handleDivisionClick(division)}
                sx={{
                  p: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                {translateWithLang(division)}
              </Box>
            ))}
          </Paper>
        </ClickAwayListener>
      </Popper>

      <TabPanel value={activeTab} index={0}>
  <Grid container spacing={2}>
    <Grid item xs={12} md={12}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>{translateWithLang("Process Gantt Chart")}</Typography>
          <Workflow/>
        </CardContent>
      </Card>
    </Grid>

     <Grid item xs={12} md={6}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>{translateWithLang("Strategic Goals")}</Typography>
          {strategicGoals.map((goal, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>{translateWithLang(goal.name)} ({translateWithLang(goal.division)})</Typography>
                <Typography>{goal.progress}%</Typography>
              </Box>
              <LinearProgress variant="determinate" value={goal.progress} />
            </Box>
          ))}
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12} md={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>{translateWithLang("Data Integration Progress")}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <RadarChart outerRadius={90} width={400} height={250} data={dataIntegrationProgress}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" tickFormatter={(value) => translateWithLang(value)} />
                  <PolarRadiusAxis angle={30} domain={[0, 100]} />
                  <Radar name={translateWithLang("Integration Progress")} dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                  <Legend formatter={(value) => translateWithLang(value)} />
                </RadarChart>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>{translateWithLang("Revenue Streams")}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <PieChart width={300} height={250}>
                  <Pie
                    data={revenueData}
                    cx={150}
                    cy={125}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name }) => translateWithLang(name)}
                  >
                    {revenueData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip formatter={(value, name) => [value, translateWithLang(name)]} />
                  <Legend formatter={(value) => translateWithLang(value)} />
                </PieChart>
                <BarChart width={300} height={250} data={revenueData}>
                  <XAxis dataKey="name" tickFormatter={(value) => translateWithLang(value)} />
                  <YAxis />
                  <Tooltip formatter={(value, name) => [value, translateWithLang(name)]} />
                  <Legend formatter={(value) => translateWithLang(value)} />
                  <Bar dataKey="growth" fill="#82ca9d" name={translateWithLang("Growth %")} />
                </BarChart>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>

        <Grid item xs={12} md={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>{translateWithLang("Overall KPIs")}</Typography>
              {overallKpis.map((kpi, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>{translateWithLang(kpi.name)}</Typography>
                    <Typography>{kpi.value}% / {kpi.target}%</Typography>
                  </Box>
                  <LinearProgress variant="determinate" value={(kpi.value / kpi.target) * 100} />
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>{translateWithLang("Geographic Hot Zone")}</Typography>
              <GeographicHotZoneMap />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
</TabPanel>

           <TabPanel value={activeTab} index={5}>
        <Typography variant="h5">{translateWithLang("Select a division from the menu above")}</Typography>
      </TabPanel>


      {Object.entries(divisionKpis).map(([division, kpis], index) => (
        <TabPanel key={division} value={activeTab} index={index + 1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {translateWithLang(division).charAt(0).toUpperCase() + translateWithLang(division).slice(1)} {translateWithLang("Division Goals and Scope")}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>{translateWithLang("goals")}:</Typography>
                  <Typography sx={{ mb: 2 }}>{translateWithLang(`${division}_goals`)}</Typography>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>{translateWithLang("scope")}:</Typography>
                  <Typography>{translateWithLang(`${division}_scope`)}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {translateWithLang(division).charAt(0).toUpperCase() + translateWithLang(division).slice(1)} {translateWithLang("Division KPIs")}
                  </Typography>
                  {kpis.map((kpi, idx) => (
                    <Box key={idx} sx={{ mb: 2 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>{translateWithLang(kpi.name)}</Typography>
                        <Typography>{kpi.value} / {kpi.target}</Typography>
                      </Box>
                      <LinearProgress variant="determinate" value={(kpi.value / kpi.target) * 100} />
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <PerformanceTrendCard division={division} translateWithLang={translateWithLang} />
</Grid>
            <Grid item xs={12} md={6}>
  <Card>
    <CardContent>
      <Typography variant="h6" gutterBottom>{translateWithLang("Multi-Metric Comparison")}</Typography>
      <ResponsiveContainer width="100%" height={300}>
        <RadarChart outerRadius={90} data={multiMetricComparisonData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="metric" tickFormatter={(value) => translateWithLang(value)} />
          <PolarRadiusAxis angle={30} domain={[0, 100]} />
          <Radar
            name={translateWithLang(division)}
            dataKey={division}
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
          <Tooltip
            formatter={(value) => [value, translateWithLang(division)]}
            labelFormatter={(label) => translateWithLang(label)}
          />
          <Legend formatter={(value) => translateWithLang(value)} />
        </RadarChart>
      </ResponsiveContainer>
    </CardContent>
  </Card>
</Grid>
          </Grid>
        </TabPanel>
      ))}


    </Box>
  );
};



const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default StrategicPlanDashboard;