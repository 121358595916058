const translations = {
  "Integrated Strategic Plan Dashboard - AI/IT Division": {
    en: "Integrated Strategic Plan Dashboard - AI/IT Division",
    fa: "داشبورد طرح استراتژیک یکپارچه - بخش هوش مصنوعی/فناوری اطلاعات"
  },
  "Divisions": {
    en: "Divisions",
    fa: "بخش‌ها"
  },
  "Dentistry": {
    en: "Dentistry",
    fa: "دندانپزشکی"
  },
  "MMT": {
    en: "MMT",
    fa: "ام‌ام‌تی"
  },
  "Diabetes": {
    en: "Diabetes",
    fa: "دیابت"
  },
  "Laboratory": {
    en: "Laboratory",
    fa: "آزمایشگاه"
  },
  "Specialists": {
    en: "Specialists",
    fa: "متخصصان"
  },
  "Emergency": {
    en: "Emergency",
    fa: "اورژانس"
  },
  "General Practice": {
    en: "General Practice",
    fa: "پزشکی عمومی"
  },
  "Pediatrics": {
    en: "Pediatrics",
    fa: "اطفال"
  },
  "Cardiology": {
    en: "Cardiology",
    fa: "قلب و عروق"
  },
  "Neurology": {
    en: "Neurology",
    fa: "مغز و اعصاب"
  },
  "Select a division from the menu above": {
    en: "Select a division from the menu above",
    fa: "لطفاً یک بخش را از منوی بالا انتخاب کنید"
  },
  "Division Goals and Scope": { en: "Division Goals and Scope", fa: ":" },
  "Overview": { en: "Overview", fa: "نمای کلی" },
  "Medical": { en: "Medical", fa: "پزشکی" },
  "Operational": { en: "Operational", fa: "عملیاتی" },
  "Administrative": { en: "Administrative", fa: "اداری" },
  "Accounting": { en: "Accounting", fa: "حسابداری" },
  "Process Gantt Chart": { en: "Process Gantt Chart", fa: "نمودار گانت فرآیند" },
  "Overall KPIs": { en: "Overall KPIs", fa: "شاخص‌های کلیدی عملکرد کلی" },
  "Strategic Goals": { en: "Strategic Long Term Goals - Over 1 year", fa: "اهداف بلند مدت استراتژیک - بالای یک سال" },
  "Data Integration Progress": { en: "Data Integration Progress", fa: "پیشرفت یکپارچه‌سازی داده‌ها" },
  "Revenue Streams": { en: "Revenue Streams", fa: "جریان‌های درآمدی" },
  "Patient Satisfaction": { en: "Patient Satisfaction", fa: "رضایت بیمار" },
  "Diagnostic Accuracy": { en: "Diagnostic Accuracy", fa: "دقت تشخیص" },
  "Cost Reduction": { en: "Cost Reduction", fa: "کاهش هزینه" },
  "Data Analysis Projects": { en: "Data Analysis Projects", fa: "پروژه‌های تحلیل داده" },
  "AI Services": { en: "AI Services", fa: "خدمات هوش مصنوعی" },
  "Software Sales": { en: "Software Sales", fa: "فروش نرم‌افزار" },
  "Consulting": { en: "Consulting", fa: "مشاوره" },
  "Data Analysis": { en: "Data Analysis", fa: "تحلیل داده" },
  "Growth %": { en: "Growth %", fa: "درصد رشد" },
  "Integration Progress": { en: "Integration Progress", fa: "پیشرفت یکپارچه‌سازی" },
  "Goals": { en: "Goals", fa: "اهداف" },
  "Scope": { en: "Scope", fa: "محدوده" },
  "Division KPIs": { en: "Division KPIs", fa: "شاخص‌های کلیدی عملکرد بخش" },
  "Switch to English": { en: "Switch to English", fa: "تغییر به انگلیسی" },
  "Switch to Persian": { en: "Switch to Persian", fa: "تغییر به فارسی" },
   "medical": {
    en: "Medical",
    fa: "پزشکی"
  },
  "operational": {
    en: "Operational",
    fa: "عملیاتی"
  },
  "administrative": {
    en: "Administrative",
    fa: "اداری"
  },
  "accounting": {
    en: "Accounting",
    fa: "حسابداری"
  },
  "goals": {
    en: "Goals",
    fa: "اهداف"
  },
  "scope": {
    en: "Scope",
    fa: "محدوده"
  },
  "Select KPI": {
    en: "Select KPI",
    fa: "انتخاب شاخص کلیدی عملکرد"
  },
  "Implement AI-driven diagnosis support": {
    en: "Implement AI-driven diagnosis support",
    fa: "پیاده‌سازی پشتیبانی تشخیص مبتنی بر هوش مصنوعی"
  },
  "Optimize resource allocation": {
    en: "Optimize resource allocation",
    fa: "بهینه‌سازی تخصیص منابع"
  },
  "Automate administrative workflows": {
    en: "Automate administrative workflows",
    fa: "خودکارسازی گردش کار اداری"
  },
  "Implement predictive financial modeling": {
    en: "Implement predictive financial modeling",
    fa: "پیاده‌سازی مدل‌سازی مالی پیش‌بینی‌کننده"
  },
  "Develop integrated data platform": {
    en: "Develop integrated data platform",
    fa: "توسعه پلتفرم داده یکپارچه"
  },
  "Launch patient mobile app": {
    en: "Launch patient mobile app",
    fa: "راه‌اندازی اپلیکیشن موبایل بیمار"
  },
  "All": {
    en: "All",
    fa: "همه"
  },

  // Medical KPIs
  "Diagnosis Accuracy": {
    en: "Diagnosis Accuracy",
    fa: "دقت تشخیص"
  },
  "Treatment Efficacy": {
    en: "Treatment Efficacy",
    fa: "اثربخشی درمان"
  },
  "Patient Readmission Rate": {
    en: "Patient Readmission Rate",
    fa: "نرخ پذیرش مجدد بیمار"
  },
  "AI-Assisted Diagnoses": {
    en: "AI-Assisted Diagnoses",
    fa: "تشخیص‌های با کمک هوش مصنوعی"
  },
  "Clinical Trial Matching": {
    en: "Clinical Trial Matching",
    fa: "تطبیق آزمایش بالینی"
  },

  // Operational KPIs
  "Resource Utilization": {
    en: "Resource Utilization",
    fa: "استفاده از منابع"
  },
  "Patient Wait Time (mins)": {
    en: "Patient Wait Time (mins)",
    fa: "زمان انتظار بیمار (دقیقه)"
  },
  "Staff Productivity": {
    en: "Staff Productivity",
    fa: "بهره‌وری کارکنان"
  },
  "Equipment Downtime": {
    en: "Equipment Downtime",
    fa: "زمان خرابی تجهیزات"
  },
  "Inventory Turnover Rate": {
    en: "Inventory Turnover Rate",
    fa: "نرخ گردش موجودی"
  },

  // Administrative KPIs
  "Process Automation": {
    en: "Process Automation",
    fa: "خودکارسازی فرآیند"
  },
  "Compliance Rate": {
    en: "Compliance Rate",
    fa: "نرخ انطباق"
  },
  "Document Processing Time (hrs)": {
    en: "Document Processing Time (hrs)",
    fa: "زمان پردازش اسناد (ساعت)"
  },
  "Staff Training Completion": {
    en: "Staff Training Completion",
    fa: "تکمیل آموزش کارکنان"
  },
  "Patient Data Accuracy": {
    en: "Patient Data Accuracy",
    fa: "دقت داده‌های بیمار"
  },

  // Accounting KPIs
  "Billing Accuracy": {
    en: "Billing Accuracy",
    fa: "دقت صورتحساب"
  },
  "Revenue Cycle Days": {
    en: "Revenue Cycle Days",
    fa: "روزهای چرخه درآمد"
  },
  "Claim Denial Rate": {
    en: "Claim Denial Rate",
    fa: "نرخ رد ادعا"
  },
  "Cost per Patient": {
    en: "Cost per Patient",
    fa: "هزینه به ازای هر بیمار"
  },
  "Profit Margin": {
    en: "Profit Margin",
    fa: "حاشیه سود"
  },


  "medical_goals": {
    en: "Enhance patient care through AI-driven diagnostics and personalized treatment plans.",
    fa: "ارتقای مراقبت از بیمار از طریق تشخیص مبتنی بر هوش مصنوعی و برنامه‌های درمانی شخصی‌سازی شده."
  },
  "medical_scope": {
    en: "Implement machine learning for disease prediction, optimize treatment protocols, and facilitate clinical trial matching.",
    fa: "پیاده‌سازی یادگیری ماشین برای پیش‌بینی بیماری، بهینه‌سازی پروتکل‌های درمانی و تسهیل تطبیق آزمایش‌های بالینی."
  },

  "operational_goals": {
    en: "Streamline clinic operations and improve resource utilization through data-driven decision making.",
    fa: "بهینه‌سازی عملیات کلینیک و بهبود استفاده از منابع از طریق تصمیم‌گیری مبتنی بر داده."
  },
  "operational_scope": {
    en: "Optimize scheduling, predict resource needs, automate inventory management, and enhance overall operational efficiency.",
    fa: "بهینه‌سازی زمان‌بندی، پیش‌بینی نیازهای منابع، خودکارسازی مدیریت موجودی و افزایش کارایی کلی عملیاتی."
  },

  "administrative_goals": {
    en: "Automate administrative processes and improve compliance through intelligent systems.",
    fa: "خودکارسازی فرآیندهای اداری و بهبود انطباق از طریق سیستم‌های هوشمند."
  },
  "administrative_scope": {
    en: "Implement AI for document processing, automate reporting, enhance data accuracy, and ensure regulatory compliance.",
    fa: "پیاده‌سازی هوش مصنوعی برای پردازش اسناد، خودکارسازی گزارش‌دهی، افزایش دقت داده‌ها و اطمینان از انطباق با مقررات."
  },

  "accounting_goals": {
    en: "Optimize financial performance and provide predictive financial insights.",
    fa: "بهینه‌سازی عملکرد مالی و ارائه بینش‌های مالی پیش‌بینی‌کننده."
  },
  "accounting_scope": {
    en: "Implement AI for revenue cycle management, automate billing processes, provide predictive financial modeling, and optimize cost structures.",
    fa: "پیاده‌سازی هوش مصنوعی برای مدیریت چرخه درآمد، خودکارسازی فرآیندهای صورتحساب، ارائه مدل‌سازی مالی پیش‌بینی‌کننده و بهینه‌سازی ساختارهای هزینه."
  },  "Performance Trend": {
    en: "Performance Trend",
    fa: "روند عملکرد"
  },
  "Multi-Metric Comparison": {
    en: "Multi-Metric Comparison",
    fa: "مقایسه چند معیاره"
  },
  "Performance": {
    en: "Performance",
    fa: "عملکرد"
  },
  "Efficiency": {
    en: "Efficiency",
    fa: "کارایی"
  },
  "Customer Satisfaction": {
    en: "Customer Satisfaction",
    fa: "رضایت مشتری"
  },
  "Innovation": {
    en: "Innovation",
    fa: "نوآوری"
  },
  "Data Quality": {
    en: "Data Quality",
    fa: "کیفیت داده"
  },
  "Jan": {
    en: "Jan",
    fa: "ژانویه"
  },
  "Feb": {
    en: "Feb",
    fa: "فوریه"
  },
  "Mar": {
    en: "Mar",
    fa: "مارس"
  },
  "Apr": {
    en: "Apr",
    fa: "آوریل"
  },
  "May": {
    en: "May",
    fa: "مه"
  },
  "Jun": {
    en: "Jun",
    fa: "ژوئن"
  },
};

export const translate = (key, language) => {
  if (translations[key] && translations[key][language]) {
    return translations[key][language];
  }
  console.warn(`Translation missing for key: "${key}" in language: "${language}"`);
  return key; // Return the key itself if no translation is found
};